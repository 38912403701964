import styled from 'styled-components';

interface ISpacing {
  $size: number;
  $vertical?: boolean;
}

export const Spacing = styled.div<ISpacing>`
  width: ${({ $size, $vertical }) => ($vertical ? `${$size}px` : '100%')};
  height: ${({ $size, $vertical }) => ($vertical ? '100%' : `${$size}px`)};
`;

export default Spacing;
