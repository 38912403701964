import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const authStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const authSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const authError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const authCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.AUTH_START]: authStart,
  [Types.AUTH_SUCCESS]: authSuccess,
  [Types.AUTH_ERROR]: authError,
  [Types.AUTH_CLEAN_UP]: authCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
