import { useState } from 'react';
import { AnimatePresence, wrap } from 'framer-motion';
import { Row } from 'components/Row';
import { IMAGES } from './Images';
import {
  ActiveThumbnail,
  Container,
  MotionDiv,
  Slider,
  StyledImage,
  ThumbnailContainer,
  CarouselContainer,
} from './styled';

export const ImageCarousel = () => {
  const [[imageCount, direction], setImageCount] = useState([0, 0]);

  const activeImageIndex = wrap(0, IMAGES.length, imageCount);

  const sliderVariants = {
    incoming: (direction: number) => ({
      x: direction > 0 ? '100%' : '-100%',
      scale: 1.2,
      opacity: 0,
    }),
    active: { x: 0, scale: 1, opacity: 1 },
    exit: (direction: number) => ({
      x: direction > 0 ? '-100%' : '100%',
      scale: 1,
      opacity: 0.2,
    }),
  };

  const sliderTransition = {
    duration: 1,
    ease: [0.56, 0.03, 0.12, 1.04],
  };

  const swipeToImage = swipeDirection => {
    setImageCount([imageCount + swipeDirection, swipeDirection]);
  };

  const dragEndHandler = dragInfo => {
    const draggedDistance = dragInfo.offset.x;
    const swipeThreshold = 50;
    if (draggedDistance > swipeThreshold) {
      swipeToImage(-1);
    } else if (draggedDistance < -swipeThreshold) {
      swipeToImage(1);
    }
  };

  const skipToImage = imageId => {
    let changeDirection;
    if (imageId > activeImageIndex) {
      changeDirection = 1;
    } else if (imageId < activeImageIndex) {
      changeDirection = -1;
    }
    setImageCount([imageId, changeDirection]);
  };

  return (
    <CarouselContainer>
      <Container>
        <Slider>
          <AnimatePresence initial={false} custom={direction}>
            <MotionDiv
              key={imageCount}
              style={{
                backgroundImage: `url(${require(
                  `../../assets/img/cuadros/${IMAGES[activeImageIndex].name}`
                )})`,
              }}
              custom={direction}
              variants={sliderVariants}
              initial="incoming"
              animate="active"
              exit="exit"
              transition={sliderTransition}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(_, dragInfo) => dragEndHandler(dragInfo)}
            />
          </AnimatePresence>
        </Slider>
      </Container>
      <Row $justifyContent="center">
        {IMAGES.map(image => (
          <ThumbnailContainer key={image.id} onClick={() => skipToImage(image.id)}>
            <StyledImage src={require(`../../assets/img/cuadros/${image.name}`)} alt="Cuadro" />
            <ActiveThumbnail $active={image.id === activeImageIndex} />
          </ThumbnailContainer>
        ))}
      </Row>
    </CarouselContainer>
  );
};
