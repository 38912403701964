import { createActions } from 'reduxsauce';

export interface AuthState {
  data: IAuthData;
  isLoading: boolean;
  error: string;
}

export interface IAuthData {
  id: string;
  name: string;
  lastname: string;
  email: string;
  role: string;
  user_id: string;
  created_at: string;
}

export interface AuthTypes {
  AUTH: 'AUTH';
  AUTH_START: 'AUTH_START';
  AUTH_SUCCESS: 'AUTH_SUCCESS';
  AUTH_ERROR: 'AUTH_ERROR';
  AUTH_CLEAN_UP: 'AUTH_CLEAN_UP';
}

const { Types, Creators } = createActions<AuthTypes>({
  auth: ['data'],
  authStart: null,
  authSuccess: ['data'],
  authError: ['error'],
  authCleanUp: null,
});

export { Types };

export default Creators;
