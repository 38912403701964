import Column from 'components/Column';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { H4 } from 'components/Typography';
import { ImageCarousel } from 'components/ImageCarousel';
import { HeaderVideo, Title, TextContainer } from './styled';

export const LandingScreen = () => {
  return (
    <>
      <Header />
      <Column $alignItems="center" $justifyItems="center" $textAlign="center">
        <Title dangerouslySetInnerHTML={{ __html: 'FABIANA YUSTMAN<br /><i>Arte</i>' }} />
        <HeaderVideo autoPlay loop muted playsInline id="landing-video">
          <source src={require('../../assets/videos/inicio.mp4')} type="video/mp4" />
        </HeaderVideo>
      </Column>
      <ImageCarousel />
      <TextContainer>
        <H4>
          Dicen que el arte sana. Pintar, es para mí, entrar en un estado de meditación. Paso horas
          sin pensar en el mundo exterior. Mi cabeza imagina una obra y luego mis manos pintan otra
          cosa. Pintan lo que mi alma y mis sentimientos sugieren en ese momento. En cada lienzo,
          encuentro un reflejo de mi ser.
        </H4>
      </TextContainer>
      <Footer />
    </>
  );
};
