import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const CTA = ({ children, ...props }) => {
  if (props.href) {
    return <a {...props}>{children}</a>;
  }
  if (props.to) {
    const { to, ...rest } = props;
    return (
      <RouterLink to={to} {...rest}>
        {children}
      </RouterLink>
    );
  }
  return <button {...props}>{children}</button>;
};

export const Link = styled(CTA)<{ $color?: string; $fontType?: string; $hasUnderline?: boolean }>`
  background: none;
  border: none;
  color: ${({ $color }) => $color || 'inherit'};
  ${({ theme, $fontType }) => ($fontType ? theme.fonts[$fontType] : theme.fonts.regular)};
  cursor: pointer;
  padding: 0;
  outline: inherit;
  text-align: ${({ $textAlign }) => $textAlign || 'left'};
  text-decoration: ${({ $hideUnderline }) => ($hideUnderline ? 'none' : 'underline')};
  font-size: 18px;
  span:hover,
  &:hover span,
  &:hover {
    color: ${({ theme }) => theme.colors.gulfStream};
    text-decoration: underline;
  }
`;
