import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store/create-store';
import { IUserData, UserState } from '../store/user/types';

export const getAuthState = (state: RootState): UserState => state.auth;

export const getUserData = createSelector(
  [getAuthState],
  (state: UserState): IUserData => state?.data
);
