import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const CTA = ({ children, ...props }) => {
  if (props.href) {
    return <a {...props}>{children}</a>;
  }
  if (props.to) {
    const { to, ...rest } = props;
    return (
      <RouterLink to={to} {...rest}>
        {children}
      </RouterLink>
    );
  }
  return <button {...props}>{children}</button>;
};

export const ButtonBase = styled(CTA)`
  border: none;
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
`;

export const Button = styled(CTA)<{
  align?: string;
  $disabled?: boolean;
}>`
  background: ${({ theme }) => theme.colors.gulfStream};
  border: none;
  outline: none;
  text-align: ${({ align }) => align || 'center'};
  border-radius: 100px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  letter-spacing: 0.1em;
  line-height: 14px;
  outline: inherit;
  padding: 11px 20px;
  text-decoration: none;
  text-transform: uppercase;
  ${({ theme }) => theme.fonts.semiBold};
  width: 100%;

  ${({ $disabled, theme }) =>
    $disabled
      ? `
    background: ${theme.shadows.black(0.5)};
      `
      : `
    background: ${theme.colors.gulfStream};
    cursor: pointer;

    &:hover {
      background: ${`linear-gradient(to right, ${theme.colors.gulfStream} 0%, ${theme.colors.shaft} 100%)`};
      color: ${theme.colors.white};
      outline: none !important;
    }
  `}
`;
