import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Container } from './styled';

export const CheckoutScreen = () => {
  return (
    <>
      <Header />
      <Container>
        <></>
      </Container>
      <Footer />
    </>
  );
};
