const colors = {
  black: '#000000',
  concrete: '#F3F2F2',
  disabled: '#F0F0F0',
  errorRed: '#EF2542',
  gray: '#828282',
  lightGray: '#DADADA',
  matterhorn: '#493C47',
  mercury: '#E9E9E9',
  shaft: '#272727',
  silver: '#C4C4C4',
  softGray: '#F2F2F2',
  success: '#008563',
  transparent: 'transparent',
  white: '#FFFFFF',
  gulfStream: '#6FA9AC',
};

export default colors;
