import { createActions } from 'reduxsauce';

export interface FaqsState {
  data: IFaqsData[];
  isLoading: boolean;
  error: string;
}
export interface IFaqsData {
  id: number;
  question: string;
  answer: string;
  active: boolean;
}

export interface FaqsTypes {
  FAQS: 'FAQS';
  FAQS_START: 'FAQS_START';
  FAQS_SUCCESS: 'FAQS_SUCCESS';
  FAQS_ERROR: 'FAQS_ERROR';
  FAQS_CLEAN_UP: 'FAQS_CLEAN_UP';
}

const { Types, Creators } = createActions<FaqsTypes>({
  faqs: ['data'],
  faqsStart: null,
  faqsSuccess: ['data'],
  faqsError: ['error'],
  faqsCleanUp: null,
});

export { Types };

export default Creators;
