import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { H2 } from 'components/Typography';
import { Button } from 'components/Button';
import Loader from 'components/Loader';
import { showToast, Toast } from 'components/Toast';
import Input, { onChangeType } from 'components/Input';
import { emailFormat } from 'helpers/regexp';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { handleSession } from 'hooks/handleSession';
import { getAuthState } from 'selectors/auth';
import { getUserId, setRoleType, setUserId } from 'services/storage';
import { loginAuth } from 'store/auth/actions';
import { getUserProfile } from 'store/user/actions';
import { Container, FormContainer } from './styled';

export const LoginScreen = () => {
  const form: any = useRef();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isExpired } = handleSession();
  const authState = useAppSelector(getAuthState);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = () => {
    dispatch(loginAuth(email, password));
  };

  useEffect(() => {
    if (authState.data && !authState.isLoading && !authState.error) {
      setUserId(authState.data.user_id);
      setRoleType(authState.data.role);
      dispatch(getUserProfile(authState.data.user_id));
      navigate('/dashboard');
    } else if (authState.error) {
      showToast(authState.error, 'error');
    }
  }, [authState]);

  useEffect(() => {
    if (getUserId() && !isExpired()) navigate('/dashboard');
  }, []);

  const isValid = !!email && !!password && emailFormat.test(email) && password.length >= 8;

  return (
    <>
      {authState.isLoading && <Loader type="dots" />}
      <Container>
        <Toast />
        <H2 $color={theme.colors.black} $fontType="semiBold">
          PANEL DE CONTROL
        </H2>
        <Spacing $size={40} />
        <FormContainer ref={form}>
          <Input
            id="email"
            placeholder="Email"
            value={email}
            textOnChange={(e: onChangeType) => setEmail(e.target.value)}
            fullWidth
            withErrors={!emailFormat.test(email) && !!email}
            leyend={!emailFormat.test(email) && !!email ? 'El email ingresado no es válido.' : ''}
          />
          <Spacing $size={16} />
          <Input
            id="password"
            placeholder="Contraseña"
            value={password}
            textOnChange={(e: onChangeType) => setPassword(e.target.value)}
            fullWidth
            type="password"
          />
          <Spacing $size={32} />
          <Button onClick={handleSubmit} $disabled={!isValid}>
            Iniciar Sesión
          </Button>
        </FormContainer>
      </Container>
    </>
  );
};
