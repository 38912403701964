import { FC } from 'react';
import Select from 'react-select';

type OptionType = {
  value: string;
  label: string;
};

interface Props {
  options: OptionType[];
  isMulti?: boolean;
  onChange;
  placeholder?: string;
  value?: OptionType;
}

export const InputSelect: FC<Props> = ({ options, isMulti, onChange, placeholder, value }) => {
  return (
    <Select
      options={options}
      isMulti={isMulti}
      onChange={onChange}
      placeholder={placeholder || 'Seleccionar una opción'}
      value={value}
    />
  );
};
