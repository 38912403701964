import { combineReducers } from 'redux';
import authReducer from './auth/reducers';
import cuadrosReducer from './cuadros/reducers';
import faqsReducer from './faqs/reducers';
import seriesReducer from './series/reducers';
import userReducer from './user/reducers';

export default combineReducers({
  auth: authReducer,
  cuadros: cuadrosReducer,
  faqs: faqsReducer,
  series: seriesReducer,
  user: userReducer,
});
