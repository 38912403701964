import supabase from 'supabase';
import { Dispatch } from 'redux';
import SERIES_TYPES from './types';
import { getUserId } from 'services/storage';

export const getSeries =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(SERIES_TYPES.seriesStart());

    const { data, error } = await supabase.from('series').select();

    if (data?.length) dispatch(SERIES_TYPES.seriesSuccess(data));
    else if (error) dispatch(SERIES_TYPES.seriesError(error.message));
  };

export const deleteSeries =
  (id: number) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(SERIES_TYPES.seriesStart());

    const { data, error } = await supabase
      .from('series')
      .update({ active: false, deleted_from: getUserId(), deleted_at: new Date() })
      .eq('id', id)
      .select();

    if (data?.length) dispatch(SERIES_TYPES.seriesSuccess(data));
    else if (error) dispatch(SERIES_TYPES.seriesError(error.message));
  };

export const updateSeries =
  (id: number, title: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(SERIES_TYPES.seriesStart());

    const { data, error } = await supabase
      .from('series')
      .update({ title, updated_from: getUserId(), updated_at: new Date() })
      .eq('id', id)
      .select();

    if (data?.length) dispatch(SERIES_TYPES.seriesSuccess(data));
    else if (error) dispatch(SERIES_TYPES.seriesError(error.message));
  };

export const insertSerie =
  (title: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(SERIES_TYPES.seriesStart());

    const { data, error } = await supabase
      .from('series')
      .insert({ title, active: true, inserted_from: getUserId(), inserted_at: new Date() })
      .select();

    if (data?.length) dispatch(SERIES_TYPES.seriesSuccess(data));
    else if (error) dispatch(SERIES_TYPES.seriesError(error.message));
  };
