import { createActions } from 'reduxsauce';

export interface SeriesState {
  data: ISeriesData[];
  isLoading: boolean;
  error: string;
}
export interface ISeriesData {
  id: number;
  title: string;
  active: boolean;
}

export interface SeriesTypes {
  SERIES: 'SERIES';
  SERIES_START: 'SERIES_START';
  SERIES_SUCCESS: 'SERIES_SUCCESS';
  SERIES_ERROR: 'SERIES_ERROR';
  SERIES_CLEAN_UP: 'SERIES_CLEAN_UP';
}

const { Types, Creators } = createActions<SeriesTypes>({
  series: ['data'],
  seriesStart: null,
  seriesSuccess: ['data'],
  seriesError: ['error'],
  seriesCleanUp: null,
});

export { Types };

export default Creators;
