import { getExpiresIn } from 'services/storage';

export function handleSession() {
  const isExpired = () => {
    if (getExpiresIn()) {
      const dateNow = new Date().getTime();
      const expiresIn = parseInt(getExpiresIn() || '');

      return dateNow > expiresIn;
    }
    return true;
  };

  return {
    isExpired,
  };
}
