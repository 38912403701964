import Column from 'components/Column';
import styled from 'styled-components';

export const Container = styled(Column)`
  justify-items: center;
  text-align: center;
  justify-content: center;
  padding: 160px 32px;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.fonts.regular};
  font-size: 34px;
  line-height: 40px;
`;

export const Description = styled(Title)`
  font-size: 15px;
  line-height: 22px;
`;
