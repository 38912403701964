export const setCartCuadros = (cuadros: string) => {
  localStorage.setItem('cc', cuadros);
};

export const getCartCuadros = () => localStorage.getItem('cc');

export const clearCartCuadros = () => {
  localStorage.removeItem('cc');
};

export const setUserId = (id: string) => {
  localStorage.setItem('id', id);
};

export const setRoleType = (roleType: string) => {
  localStorage.setItem('roleType', roleType);
};

export const setExpiresIn = (expiresIn: string) => {
  localStorage.setItem('expiresIn', expiresIn);
};

export const getUserId = () => localStorage.getItem('id');

export const getRoleType = () => localStorage.getItem('roleType');

export const getExpiresIn = () => localStorage.getItem('expiresIn');

export const clearStorage = () => {
  localStorage.removeItem('id');
  localStorage.removeItem('roleType');
  localStorage.removeItem('expiresIn');
};
