import { motion } from 'framer-motion';
import styled from 'styled-components';

export const MotionDiv = styled(motion.div)`
  position: fixed;
  height: 100%;
  width: 30%;
  right: 0;
  top: 0;
  background: ${({ theme }) => theme.colors.white};
  z-index: 10;
  padding: 24px;
  border-left: 1px solid ${({ theme }) => theme.colors.softGray};

  @media (max-width: 800px) {
    width: -webkit-fill-available;
    right: auto;
    top: auto;
  }
`;

export const CartContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.softGray};
  margin-top: 16px;
`;

export const CartItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.softGray};
  padding: 20px 0;
`;

export const StyledImage = styled.img`
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 3px;
`;
