import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutGroup, useCycle } from 'framer-motion';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Column from 'components/Column';
import Icon, { IconNames } from 'components/Icon';
import { SlidingCart } from 'components/SlidingCart';
import { SlidingMenu } from './SlidingMenu';
import {
  Container,
  MenuItem,
  MotionButton,
  Underline,
  MenuItems,
  Logo,
  BarContainer,
} from './styled';

export const Header = () => {
  const navigate = useNavigate();
  const iconRef = useRef<any>(null);
  const [selected, setSelected] = useState(0);
  const [isMenuOpen, toggleMenuOpen] = useCycle(false, true);
  const [isCartOpen, toggleCartOpen] = useState(false);
  const menuItems = ['Inicio', 'Quien Soy', 'Arte', 'Preguntas Frecuentes', 'Contacto'];

  const handleClick = (i: number) => {
    setSelected(i);
    if (i === 0) navigate('/');
    if (i === 1) navigate('/bio');
    if (i === 2) navigate('/art');
    if (i === 3) navigate('/faqs');
    if (i === 4) navigate('/contact');
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    if (url.pathname.includes('bio')) setSelected(1);
    else if (url.pathname.includes('art') || url.pathname.includes('detail')) setSelected(2);
    else if (url.pathname.includes('faqs')) setSelected(3);
    else if (url.pathname.includes('contact')) setSelected(4);
    else if (url.pathname.includes('checkout')) setSelected(5);
    else setSelected(0);
  }, []);

  return (
    <BarContainer $isMenuOpen={isMenuOpen} $isCartOpen={isCartOpen}>
      <Container $justifyContent="space-between">
        <Column>
          <Row $justifyContent="space-between">
            <MotionButton whileTap={{ scale: 0.9 }}>
              <Icon
                onClick={toggleMenuOpen}
                name={isMenuOpen ? IconNames.XClose : IconNames.Menu}
                size={32}
                color={theme.colors.gulfStream}
                style={{ padding: '24px 0' }}
              />
            </MotionButton>
            <MenuItems>
              <LayoutGroup>
                {menuItems.map((ele, i) => (
                  <MenuItem
                    key={i}
                    onClick={() => handleClick(i)}
                    animate={{ opacity: selected === i ? 1 : 0.5 }}
                  >
                    {ele}
                    {selected === i && <Underline layoutId="underline" />}
                  </MenuItem>
                ))}
              </LayoutGroup>
            </MenuItems>
          </Row>
        </Column>
        <Logo onClick={() => navigate('/')} src={require('../../assets/img/logo.png')} />
        <Column ref={iconRef} $justifyContent="end" $alignContent="center">
          <Icon
            name={isCartOpen ? IconNames.XClose : IconNames.ShoppingCart}
            color={theme.colors.gulfStream}
            size={!isCartOpen ? 22 : 32}
            style={{ cursor: 'pointer' }}
            onClick={() => toggleCartOpen(!isCartOpen)}
          />
        </Column>
      </Container>
      <SlidingCart isOpen={isCartOpen} closeCart={() => toggleCartOpen(false)} iconRef={iconRef} />
      <SlidingMenu isOpen={isMenuOpen} />
    </BarContainer>
  );
};
