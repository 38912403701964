import { useEffect } from 'react';
import Spacing from 'components/Spacing';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getSeriesState } from 'selectors/series';
import { getCuadrosState } from 'selectors/cuadros';
import { getSeries } from 'store/series/actions';
import { getCuadros } from 'store/cuadros/actions';
import { Serie } from './Serie';

export const ArteScreen = () => {
  const dispatch = useAppDispatch();
  const { seriesState, cuadrosState } = useAppSelector(state => ({
    seriesState: getSeriesState(state),
    cuadrosState: getCuadrosState(state),
  }));

  useEffect(() => {
    if (!seriesState.data) dispatch(getSeries());
    if (!cuadrosState.data) dispatch(getCuadros());
  }, []);

  const seriesConCuadros = cuadrosState.data?.map(cuadro => cuadro.id_serie);
  const seriesFiltradas = seriesState.data?.filter(serie => seriesConCuadros?.includes(serie.id));

  return (
    <>
      <Header />
      {seriesFiltradas?.map(serie => (
        <Serie key={serie.id} serie={serie} cuadros={cuadrosState.data} />
      ))}
      <Spacing $size={32} />
      <Footer />
    </>
  );
};
