import theme from 'config/theme';
import { useNavigate } from 'react-router-dom';
import Spacing from 'components/Spacing';
import { Row, RowToGrid } from 'components/Row';
import Icon, { IconNames } from 'components/Icon';
import { Container, Title, Description, Content } from './styled';

export const Footer = () => {
  const navigate = useNavigate();

  const sendWhatsapp = () => {
    const msg = 'Buenas tardes, me gustaria recibir informacion respecto a la página';
    const whatsappURL = `https://wa.me/+5491159512300?text=${msg}`;
    window.open(whatsappURL, '_blank');
  };

  const openInstagram = () => {
    window.open('https://www.instagram.com/fabyyustman/', '_blank');
  };

  const openFacebook = () => {
    window.open('https://www.facebook.com/share/CWetsATKJfiWySHT/?mibextid=qi2Omg', '_blank');
  };

  return (
    <Container>
      <RowToGrid $switchToGrid={500} $justifyContent="space-between">
        <Content $justifyContent="center" $alignContent="flex-start">
          <Title>Faby Yustman</Title>
          <Spacing $size={8} />
          <Description>
            Copyright © {new Date().getFullYear()} <Description $bold>Faby Yustman</Description>
          </Description>
        </Content>
        <Content $justifyContent="center" $alignContent="flex-start">
          <Title>Contenido</Title>
          <Spacing $size={8} />
          <Description onClick={() => navigate('/')} $pressable>
            Inicio
          </Description>
          <Spacing $size={4} />
          <Description onClick={() => navigate('/bio')} $pressable>
            Quien Soy
          </Description>
          <Spacing $size={4} />
          <Description onClick={() => navigate('/art')} $pressable>
            Arte
          </Description>
          <Spacing $size={4} />
          <Description onClick={() => navigate('/faqs')} $pressable>
            Preguntas Frecuentes
          </Description>
          <Spacing $size={4} />
          <Description onClick={() => navigate('/contact')} $pressable>
            Contacto
          </Description>
        </Content>
        <Content $justifyContent="center" $alignContent="flex-start">
          <Title>Social</Title>
          <Spacing $size={8} />
          <Row $pressable $alignItems="center" $justifyContent="left">
            <Icon name={IconNames.Whatsapp} size={22} color={theme.colors.white} />
            <Spacing $vertical $size={4} />
            <Description onClick={sendWhatsapp}>Whatsapp</Description>
          </Row>
          <Spacing $size={4} />
          <Row $pressable $alignItems="center" $justifyContent="left">
            <Spacing $vertical $size={3} />
            <Icon name={IconNames.Instagram} size={16} color={theme.colors.white} />
            <Spacing $vertical $size={8} />
            <Description onClick={openInstagram}>Instagram</Description>
          </Row>
          <Spacing $size={4} />
          <Row $pressable $alignItems="center" $justifyContent="left">
            <Spacing $vertical $size={3} />
            <Icon name={IconNames.Instagram} size={16} color={theme.colors.white} />
            <Spacing $vertical $size={8} />
            <Description onClick={openFacebook}>Facebook</Description>
          </Row>
        </Content>
      </RowToGrid>
    </Container>
  );
};
