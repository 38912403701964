export const limitChars = (value: string, limit: number) => {
  if (value?.length > limit) {
    return `${value.substring(0, limit)}...`;
  } else {
    return value;
  }
};

export const abbreviateNum = (num: number) => {
  const value = Intl.NumberFormat('en-AR', {
    notation: 'standard',
    maximumFractionDigits: 1,
  }).format(num);

  return isNaN(parseFloat(value)) ? '0' : value;
};

export const getUrlSearchParamsObj = () => new URL(window.location.href).searchParams;

export const getUrlParams = () => {
  const searchParams = getUrlSearchParamsObj();

  return {
    urlPage: searchParams?.get('p')?.replace('/', ''),
    urlId: searchParams?.get('id')?.replace('/', ''),
  };
};
