export const cuadrosColumns = [
  { Header: 'Nombre', accessor: 'name' },
  { Header: 'Descripción', accessor: 'description' },
  { Header: 'Serie', accessor: 'id_serie' },
  { Header: 'Label', accessor: 'label' },
  { Header: 'Precio', accessor: 'price' },
  { Header: 'Descuento', accessor: 'discount' },
  { Header: '', accessor: 'actions' },
];

export const seriesColumns = [
  { Header: 'Titulo', accessor: 'title' },
  { Header: '', accessor: 'actions' },
];

export const faqsColumns = [
  { Header: 'Pregunta', accessor: 'question' },
  { Header: 'Respuesta', accessor: 'answer' },
  { Header: '', accessor: 'actions' },
];
