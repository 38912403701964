import { FC } from 'react';
import { HeaderItem, MotionContainer } from './styled';

interface Props {
  label: string;
  onclick?: () => void;
  lastone?: boolean;
  selected?: boolean;
}

export const HeaderListItem: FC<Props> = ({ label, onclick, selected, lastone }) => {
  return (
    <MotionContainer
      whileTap={{ scale: 0.95 }}
      onClick={onclick}
      $selected={selected}
      $lastone={lastone}
    >
      <HeaderItem $selected={selected}>{label}</HeaderItem>
    </MotionContainer>
  );
};
