import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 120px;
  text-align: center;

  @media (min-width: 801px) and (max-width: 1200px) {
    padding: 16px 60px;
  }
  @media (max-width: 800px) {
    padding: 16px;
  }
`;

export const ListCuadros = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: -webkit-fill-available;
  gap: 40px;
  justify-content: inherit;
`;
