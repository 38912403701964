import Spacing from 'components/Spacing';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Body, H3 } from 'components/Typography';
import {
  Container,
  Block,
  FullImage,
  GraySection,
  StyledImage,
  TextsContainer,
  Description,
} from './styled';

export const BioScreen = () => {
  return (
    <>
      <Header />
      <Container>
        <H3>Quien Soy</H3>
        <Spacing $size={24} />
        <Block>
          <StyledImage $float="left" src={require('../../assets/img/bio/bio_1.jpg')} />
          <TextsContainer>
            <Description>
              Fabiana Mariel Yustman, nacida en 1967, es una artista visual cuya trayectoria ha sido
              moldeada por una profunda pasión por el arte en todas sus formas. Aunque su formación
              inicial la llevó por el camino de la óptica y la contactología, su corazón siempre
              estuvo ligado al mundo de la creatividad. <br />
            </Description>
            <Description>
              Fue entre los años 2004 y 2009 cuando Fabiana decidió embarcarse en un viaje de
              autodescubrimiento a través de la fotografía, aprendiendo los secretos del arte en la
              escuela Motivarte, allí supo explorar la capacidad de la cámara para capturar la
              esencia del mundo que la rodeaba. <br />
            </Description>
            <Description>
              Sin embargo, su sed de conocimiento y expresión artística no se detuvo ahí. En 2013,
              dio un giro hacia la pintura, buscando plasmar su visión única del universo a través
              del lienzo y la paleta de colores. Durante estos años, ha tenido el privilegio de
              estudiar con diferentes maestros quienes han influido en su desarrollo como artista.
            </Description>
          </TextsContainer>
        </Block>
        <Block>
          <StyledImage $float="right" src={require('../../assets/img/bio/bio_2.jpg')} />
          <TextsContainer>
            <Description>
              Lo que distingue el trabajo de Fabiana es su capacidad para trascender los límites
              convencionales del arte. No se conforma con simplemente pintar sobre lienzos
              tradicionales; prefiere intervenir objetos y explorar una variedad de materiales para
              crear texturas y formas únicas que cautivan al espectador. <br />
            </Description>
            <Description>
              En 2015, Fabiana amplió aún más su horizonte artístico al sumergirse en el fascinante
              mundo del vidrio. Bajo la guía del maestro Carlos Herzberg, aprendió las intrincadas
              técnicas de vitrofusión y pasta de vidrio, creando así objetos escultóricos a partir
              de materiales reciclados. <br />
            </Description>
            <Description>
              Fabiana Yustman cuenta historias a través de sus obras, donde cada pincelada, cada
              pieza de vidrio fundido, es el resultado de una vida dedicada a la búsqueda de la
              belleza y la expresión creativa en todas sus manifestaciones.
            </Description>
          </TextsContainer>
        </Block>
      </Container>
      <GraySection>
        <H3>Ferias y Exposiciones</H3>
        <Spacing $size={24} />
        <Body>- Fundación Rómulo Raggio.</Body>
        <Body>- Arte y diseño en Hipódromo de Palermo.</Body>
        <Body>- Umbral espacio de arte.</Body>
        <Body>- Celebrando La Boca, teatro Verdi.</Body>
        <Body>- Facultad de derecho, UBA.</Body>
        <Body>- Arte Lamroth.</Body>
        <Body>- UCES, Universidad de Ciencias Empresariales.</Body>
        <Body>- Centro Cultural Cultura Viva</Body>
        <Body>- Victorios Art Studio, Fort Lauderdale, U.S.A</Body>
      </GraySection>
      <Container>
        <H3>Mi Taller</H3>
        <Spacing $size={24} />
        <FullImage src={require('../../assets/img/taller.jpg')} />
      </Container>
      <Footer />
    </>
  );
};
