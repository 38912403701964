import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  max-width: 800px;
`;

export const FormContainer = styled.form`
  padding: 16px 0;
  display: grid;
`;

export const InputSelectContainer = styled.div`
  color: ${({ theme }) => theme.colors.matterhorn};
  ${({ theme }) => theme.fonts.regular};
  font-size: 13px;
`;
