import { FC, ReactNode, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import theme from 'config/theme';
import { Row } from 'components/Row';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import Icon, { IconNames } from 'components/Icon';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getFaqsState } from 'selectors/faqs';
import { getFaqs } from 'store/faqs/actions';
import { IFaqsData } from 'store/faqs/types';
import {
  Container,
  Title,
  Accordion,
  AccordionHeader,
  MotionContainer,
  AccordionValue,
} from './styled';

interface FadeProps {
  children: ReactNode;
  isActive: boolean;
}
export const FaqsScreen = () => {
  const dispatch = useAppDispatch();
  const faqsState = useAppSelector(getFaqsState);
  const [itemVisible, setItemVisible] = useState<number>(-1);

  useEffect(() => {
    dispatch(getFaqs());
  }, []);

  const handleClick = (item: number) => {
    if (itemVisible === item) setItemVisible(-1);
    else setItemVisible(item);
  };

  const Fade: FC<FadeProps> = ({ children, isActive }) => {
    return (
      <AnimatePresence>
        {isActive && (
          <MotionContainer
            initial={{ opacity: 0, y: '-100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, x: '100%' }}
          >
            {children}
          </MotionContainer>
        )}
      </AnimatePresence>
    );
  };

  return (
    <>
      <Header />
      <Container>
        <Title>Información General</Title>
        {faqsState.data?.map((item: IFaqsData, i: number) => (
          <Accordion key={i} onClick={() => handleClick(i)}>
            <Row $justifyContent="space-between" $alignItems="center">
              <AccordionHeader>{item.question}</AccordionHeader>
              <Icon
                name={itemVisible === i ? IconNames.ChevronUp : IconNames.ChevronDown}
                size={32}
                color={theme.colors.softGray}
              />
            </Row>
            <Fade isActive={itemVisible === i}>
              <AccordionValue>{item.answer}</AccordionValue>
            </Fade>
          </Accordion>
        ))}
      </Container>
      <Footer />
    </>
  );
};
