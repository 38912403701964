import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px;
`;

export const Title = styled.span`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.matterhorn};
  ${({ theme }) => theme.fonts.semiBold};
`;

export const Accordion = styled.div`
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
`;

export const AccordionHeader = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.matterhorn};
  ${({ theme }) => theme.fonts.regular};
`;

export const AccordionValue = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.matterhorn};
  ${({ theme }) => theme.fonts.light};
`;

export const MotionContainer = styled(motion.div)`
  padding-top: 8px;
  display: grid;
`;
