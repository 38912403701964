import { useEffect, useState } from 'react';

export const Image = ({ src, ...imgProps }) => {
  const [showImage, setShowImage] = useState(true);
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const onError = () => {
    setShowImage(false);
  };

  if (!showImage || !imgSrc) {
    return null;
  }

  return <img {...imgProps} src={imgSrc} onError={onError} />;
};
