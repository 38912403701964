import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { Button } from 'components/Button';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { abbreviateNum } from 'hooks/utils';
import Loader from 'components/Loader';
import { Row, RowToGrid } from 'components/Row';
import { clearCartCuadros, getCartCuadros, setCartCuadros } from 'services/storage';
import { Container, StyledImage, Content, Title, Description, Price } from './styled';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getCuadrosState } from 'selectors/cuadros';
import { getCuadros } from 'store/cuadros/actions';

export const DetailScreen = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentCartCuadros = getCartCuadros();
  const cuadrosState = useAppSelector(getCuadrosState);
  const currentArrCartCuadros = currentCartCuadros?.split(',') || [];
  const [imageExpanded, setImageExpanded] = useState<string | undefined>('');

  useEffect(() => {
    if (!cuadrosState.data) dispatch(getCuadros());
  }, []);

  const cuadro = cuadrosState.data?.find(cuadro => cuadro.id === Number(id));

  useEffect(() => {
    if (!cuadro && !cuadrosState.isLoading && cuadrosState.data) navigate('/');
    else setImageExpanded(id);
  }, [cuadro]);

  const addToCart = () => {
    setCartCuadros(currentCartCuadros ? `${currentCartCuadros},${id}` : `${id}`);
    window.location.reload();
  };

  const removeFromCart = () => {
    const arr = currentArrCartCuadros?.filter(item => item !== id);
    if (arr.toString()) setCartCuadros(arr.toString());
    else clearCartCuadros();
    window.location.reload();
  };

  return (
    <>
      {cuadrosState.isLoading && <Loader type="dots" />}
      <>
        <Header />
        <Container>
          <RowToGrid $switchToGrid={700}>
            <Column>
              {imageExpanded && (
                <StyledImage
                  src={require(`../../assets/img/cuadros/cuadro_${imageExpanded}.jpg`)}
                />
              )}
              <Spacing $size={16} />
              <Row $gap={8} $flexWrap="wrap">
                <StyledImage
                  onClick={() => setImageExpanded(id)}
                  src={require(`../../assets/img/cuadros/cuadro_${id}.jpg`)}
                  $small
                />
                {cuadro?.extra_images?.map((img_id, i) => (
                  <StyledImage
                    key={i}
                    onClick={() => setImageExpanded(`${id}${img_id}`)}
                    src={require(`../../assets/img/cuadros/cuadro_${id}${img_id}.jpg`)}
                    $small
                  />
                ))}
              </Row>
            </Column>
            <Content>
              {cuadro && (
                <>
                  <Title>{cuadro?.name}</Title>
                  <Price>${abbreviateNum(parseInt(cuadro?.price || ''))}</Price>
                  <Description>{cuadro?.description}</Description>
                  <Spacing $size={16} />
                  {!currentArrCartCuadros.includes(`${id}`) ? (
                    <Button onClick={addToCart}>Agregar al Carrito</Button>
                  ) : (
                    <Button onClick={removeFromCart}>Sacar del Carrito</Button>
                  )}
                </>
              )}
            </Content>
          </RowToGrid>
        </Container>
        <Footer />
      </>
    </>
  );
};
