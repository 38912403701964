import { createActions } from 'reduxsauce';

export interface UserState {
  data: IUserData;
  isLoading: boolean;
  error: string;
}

export interface IUserData {
  id: string;
  name: string;
  lastname: string;
  email: string;
  alias: string;
  user_id: string;
  role: string;
  created_at: string;
}

export interface UserTypes {
  USER: 'USER';
  USER_START: 'USER_START';
  USER_SUCCESS: 'USER_SUCCESS';
  USER_ERROR: 'USER_ERROR';
  USER_CLEAN_UP: 'USER_CLEAN_UP';
}

const { Types, Creators } = createActions<UserTypes>({
  user: ['data'],
  userStart: null,
  userSuccess: ['data'],
  userError: ['error'],
  userCleanUp: null,
});

export { Types };

export default Creators;
