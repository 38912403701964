export const IMAGES = [
  {
    id: 0,
    name: 'cuadro_1.jpg',
  },
  {
    id: 1,
    name: 'cuadro_2.jpg',
  },
  {
    id: 2,
    name: 'cuadro_3.jpg',
  },
  {
    id: 3,
    name: 'cuadro_4.jpg',
  },
  {
    id: 4,
    name: 'cuadro_5.jpg',
  },
  {
    id: 5,
    name: 'cuadro_6.jpg',
  },
  {
    id: 6,
    name: 'cuadro_7.jpg',
  },
];
