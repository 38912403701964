import { useState } from 'react';

const useCuadrosPagination = (cuadrosData, batchSize) => {
  const [cuadros, setCuadros] = useState(cuadrosData);
  const [offset, setOffset] = useState(0);

  const loadNextBatch = () => {
    const newOffset = offset + batchSize;
    setOffset(newOffset);
    // setCuadros(prevCuadros => [
    //   ...prevCuadros,
    // ]);
  };

  const currentCuadros = cuadros.slice(0, offset + batchSize);

  return [currentCuadros, loadNextBatch];
};

export default useCuadrosPagination;
