import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const seriesStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const seriesSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const seriesError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const seriesCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.SERIES_START]: seriesStart,
  [Types.SERIES_SUCCESS]: seriesSuccess,
  [Types.SERIES_ERROR]: seriesError,
  [Types.SERIES_CLEAN_UP]: seriesCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
