import supabase from 'supabase';
import { Dispatch } from 'redux';
import AUTH_TYPES from './types';

export const signUpAuth =
  (name: string, lastname: string, email: string, password: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(AUTH_TYPES.authStart());

    const { data, error } = await supabase.auth.signUp({
      email,
      password,
    });

    const { data: signupData, error: signupError } = await supabase
      .from('users')
      .insert([{ name, lastname, email, user_id: data.user?.id, role: 'nu' }])
      .select();

    if (signupData?.length) dispatch(AUTH_TYPES.authSuccess(signupData[0]));
    else if (!signupData?.length && !signupError)
      dispatch(AUTH_TYPES.authError('Credenciales incorrectas.'));
    else if (error) dispatch(AUTH_TYPES.authError(JSON.parse(JSON.stringify(error)).message));
    else if (signupError) dispatch(AUTH_TYPES.authError(signupError));
  };

export const loginAuth =
  (email: string, password: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(AUTH_TYPES.authStart());

    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) return dispatch(AUTH_TYPES.authError(JSON.parse(JSON.stringify(error)).message));

    const { data: loginData, error: loginError } = await supabase
      .from('users')
      .select('id, name, lastname, email, user_id, role')
      .eq('user_id', data.user?.id);

    if (loginData?.length) dispatch(AUTH_TYPES.authSuccess(loginData[0]));
    else if (!loginData?.length && !loginError)
      dispatch(AUTH_TYPES.authError('Credenciales incorrectas.'));
    else if (loginError) dispatch(AUTH_TYPES.authError(loginError));
  };
