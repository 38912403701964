const IconNames: { [Keys: string]: string } = {
  ArrowLeft: 'arrow-left',
  ArrowUp: 'arrow-up',
  Cellphone: 'cellphone',
  Check: 'check',
  Checkmark: 'checkmark',
  Child: 'child',
  CreditCard: 'credit-card',
  ChevronCircle: 'chevron-circle',
  ChevronDown: 'chevron-down',
  ChevronRight: 'chevron-right',
  ChevronUp: 'chevron-up',
  DollarSign: 'dollar-sign',
  HelpCircle: 'help-circle',
  Home: 'home',
  Instagram: 'instagram',
  Linkedin: 'linkedin',
  LogIn: 'log-in',
  LogOut: 'log-out',
  Mail: 'mail',
  Menu: 'menu',
  Pen: 'pen',
  Percent: 'percent',
  Plus: 'plus',
  Search: 'search',
  Share: 'share',
  ShoppingCart: 'shopping-cart',
  Settings: 'settings',
  Tiktok: 'tiktok',
  Trash: 'trash',
  Truck: 'truck',
  User: 'user',
  Users: 'users',
  Whatsapp: 'whatsapp',
  XClose: 'x-close',
};

export default IconNames;
