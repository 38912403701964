import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px;
  max-width: 800px;
`;

export const FormContainer = styled.form`
  padding: 16px 0;
  display: grid;
`;

export const Title = styled.span`
  font-size: 32px;
  ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.shaft};
`;

export const Subtitle = styled.span`
  font-size: 20px;
  ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.shaft};
`;
