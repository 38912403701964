import { FC, CSSProperties, useState } from 'react';
import IcomoonReact from 'icomoon-react';
import { motion } from 'framer-motion';
import iconSet from '../../assets/fonts/selection.json';
import theme from 'config/theme';
import IconNames from './iconNames';

export interface IconProps {
  name: string;
  size?: number;
  color?: string;
  onClick?: () => void;
  disabled?: boolean;
  style?: CSSProperties;
}

const Icon: FC<IconProps> = (props: IconProps) => {
  const { name, color = theme.colors.black, size = 16, disabled, onClick, style } = props;

  return (
    <motion.div whileTap={{ scale: onClick ? 0.9 : 1 }}>
      <IcomoonReact
        iconSet={iconSet}
        color={disabled ? theme.colors.disabled : color}
        size={size}
        icon={name}
        onClick={onClick}
        style={style}
      />
    </motion.div>
  );
};

export default Icon;

export { IconNames };
