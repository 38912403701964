import { createBrowserRouter } from 'react-router-dom';
import { BioScreen } from 'screens/Bio';
import { ContactScreen } from 'screens/Contact';
import { FaqsScreen } from 'screens/Faqs';
import { LandingScreen } from 'screens/Landing';
import { ArteScreen } from 'screens/Arte';
import { NotFoundScreen } from 'screens/NotFound';
import { DetailScreen } from 'screens/Detail';
import { CheckoutScreen } from 'screens/Checkout';
import { LoginScreen } from 'screens/Login';
import { DashboardScreen } from 'screens/Dashboard';
import { PageDetailScreen } from 'screens/PageDetail';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingScreen />,
    errorElement: <NotFoundScreen />,
  },
  {
    path: '/detail/:id',
    element: <DetailScreen />,
  },
  {
    path: '/contact',
    element: <ContactScreen />,
  },
  {
    path: '/bio',
    element: <BioScreen />,
  },
  {
    path: '/art',
    element: <ArteScreen />,
  },
  {
    path: '/faqs',
    element: <FaqsScreen />,
  },
  {
    path: '/checkout',
    element: <CheckoutScreen />,
  },
  {
    path: '/error',
    element: <NotFoundScreen />,
  },
  {
    path: '/admin',
    element: <LoginScreen />,
  },
  {
    path: '/dashboard',
    element: <DashboardScreen />,
  },
  {
    path: '/page_detail',
    element: <PageDetailScreen />,
  },
]);
