import { Image } from 'components/Image';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 32px;
`;

export const StyledImage = styled(Image)<{ $small?: boolean }>`
  ${({ $small }) =>
    $small
      ? `
      width: 100px;
      cursor: pointer;
      border-radius: 8px;
      max-height: 100px;
      object-fit: cover;
      `
      : `
      width: 600px;
      max-height: 500px;
      object-fit: cover;
      
      @media (min-width: 701px) and (max-width: 1000px) {
        max-height: 300px;
        width: 400px;
      }
      @media (max-width: 700px) {
        max-height: 300px;
        width: 100%;
      }
      `};
`;

export const Content = styled.div`
  padding-left: 32px;

  @media (max-width: 700px) {
    padding: 16px 0;
  }
`;

export const Title = styled.div`
  font-size: 32px;
  ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.shaft};
`;

export const Price = styled.div`
  font-size: 28px;
  ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.gulfStream};
`;

export const Description = styled.div`
  font-size: 16px;
  ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.shaft};
`;
