import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const cuadrosStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const cuadrosSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const cuadrosError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const cuadrosCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.CUADROS_START]: cuadrosStart,
  [Types.CUADROS_SUCCESS]: cuadrosSuccess,
  [Types.CUADROS_ERROR]: cuadrosError,
  [Types.CUADROS_CLEAN_UP]: cuadrosCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
