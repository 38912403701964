import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row } from 'components/Row';
import Loader from 'components/Loader';
import Spacing from 'components/Spacing';
import { Button } from 'components/Button';
import { showToast, Toast } from 'components/Toast';
import { InputSelect } from 'components/InputSelect';
import { H1, UtilityText } from 'components/Typography';
import Input, { onChangeTextareaType, onChangeType } from 'components/Input';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getUrlParams } from 'hooks/utils';
import { handleSession } from 'hooks/handleSession';
import { getUserId } from 'services/storage';
import { getFaqsState } from 'selectors/faqs';
import { getSeriesState } from 'selectors/series';
import { getCuadrosState } from 'selectors/cuadros';
import { getFaqs, insertFaq, updateFaqs } from 'store/faqs/actions';
import { getSeries, insertSerie, updateSeries } from 'store/series/actions';
import { getCuadros, insertCuadro, updateCuadros } from 'store/cuadros/actions';
import { Container, FormContainer, InputSelectContainer } from './styled';

export const PageDetailScreen = () => {
  const form: any = useRef();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isExpired } = handleSession();
  const { urlPage, urlId } = getUrlParams();
  const [title, setTitle] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [label, setLabel] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState(0);
  const [idSerie, setIdSerie] = useState<number>(0);
  const [buttonPressed, setButtonPressed] = useState(false);
  const [seriesOptions, setSeriesOptions] = useState(Array<{ value: string; label: string }>());

  const { faqsState, seriesState, cuadrosState } = useAppSelector(state => ({
    faqsState: getFaqsState(state),
    seriesState: getSeriesState(state),
    cuadrosState: getCuadrosState(state),
  }));

  const getTitle = () => {
    let page = '';
    if (urlPage === 'series') page = 'serie';
    else if (urlPage === 'cuadros') page = 'cuadro';
    else page = 'pregunta frecuente';

    return `${urlId ? 'Editar ' : 'Crear '}${page}`;
  };

  useEffect(() => {
    if (urlId) {
      if (!getUserId() || isExpired()) navigate('admin');
      if (!faqsState.data) dispatch(getFaqs());
      if (!cuadrosState.data) dispatch(getCuadros());
      if (!seriesState.data) dispatch(getSeries());
    }
  }, []);

  useEffect(() => {
    if (urlId && urlPage === 'faqs' && !faqsState.isLoading && faqsState.data) {
      const element = faqsState.data?.find(item => item.id === parseInt(urlId) && item.active);
      if (element) {
        setQuestion(element.question);
        setAnswer(element.answer);
      } else {
        navigate('/dashboard');
      }
    }
  }, [faqsState]);

  useEffect(() => {
    if (!seriesState.isLoading && seriesState.data) {
      if (urlId && urlPage === 'series') {
        const element = seriesState.data?.find(item => item.id === parseInt(urlId) && item.active);
        if (element) {
          setTitle(element.title);
        } else {
          navigate('/dashboard');
        }
      }
      setSeriesOptions(
        seriesState.data.map(serie => ({ value: serie.id.toString(), label: serie.title }))
      );
    }
  }, [seriesState]);

  useEffect(() => {
    if (urlId && urlPage === 'cuadros' && !cuadrosState.isLoading && cuadrosState.data) {
      const element = cuadrosState.data.find(item => item.id === parseInt(urlId) && item.active);
      if (element) {
        setName(element.name);
        setDescription(element.description);
        setIdSerie(seriesState.data?.find(serie => serie.id === element.id_serie)?.id || 0);
        setLabel(element.label);
        setPrice(element.price);
        setDiscount(element.discount || 0);
      } else {
        navigate('/dashboard');
      }
    }
  }, [cuadrosState]);

  const showSuccessAndNavigate = () => {
    showToast('Cambio realizado exitosamente!', 'success');
    setTimeout(() => {
      navigate(`/dashboard?p=${urlPage}`), setButtonPressed(false);
    }, 3000);
  };

  const showError = () => {
    showToast('Debe completar todos los campos obligatorios', 'error');
    setButtonPressed(false);
  };

  const handleSubmit = () => {
    setButtonPressed(true);
    if (urlPage === 'series') {
      if (title) {
        if (urlId) dispatch(updateSeries(parseInt(urlId), title));
        else dispatch(insertSerie(title));
        showSuccessAndNavigate();
      } else showError();
    }

    if (urlPage === 'faqs') {
      if (question && answer) {
        if (urlId) dispatch(updateFaqs(parseInt(urlId), question, answer));
        else dispatch(insertFaq(question, answer));
        showSuccessAndNavigate();
      } else showError();
    }

    if (urlPage === 'cuadros') {
      if (name && price) {
        if (urlId)
          dispatch(
            updateCuadros(parseInt(urlId), name, description, idSerie, label, price, discount)
          );
        else dispatch(insertCuadro(name, description, idSerie, label, price, discount));
        showSuccessAndNavigate();
      } else showError();
    }
  };

  return (
    <>
      <Toast />
      <Container>
        <H1>{getTitle()}</H1>
        <Spacing $size={20} />
        <FormContainer ref={form}>
          {urlPage === 'series' && (
            <Input
              id="title"
              placeholder="Titulo *"
              value={title}
              textOnChange={(e: onChangeType) => setTitle(e.target.value)}
              fullWidth
            />
          )}
          {urlPage === 'faqs' && (
            <>
              <Input
                id="question"
                placeholder="Pregunta *"
                value={question}
                textareaOnChange={(e: onChangeTextareaType) => setQuestion(e.target.value)}
                fullWidth
                textarea
              />
              <Spacing $size={16} />
              <Input
                id="answer"
                placeholder="Respuesta *"
                value={answer}
                textareaOnChange={(e: onChangeTextareaType) => setAnswer(e.target.value)}
                fullWidth
                textarea
              />
            </>
          )}
          {urlPage === 'cuadros' && (
            <>
              <Input
                id="name"
                placeholder="Nombre *"
                value={name}
                textOnChange={(e: onChangeType) => setName(e.target.value)}
                fullWidth
              />
              <Spacing $size={16} />
              <Input
                id="description"
                placeholder="Descripción"
                value={description}
                textareaOnChange={(e: onChangeTextareaType) => setDescription(e.target.value)}
                fullWidth
                textarea
              />
              <Spacing $size={16} />
              <InputSelectContainer>
                <UtilityText>Serie *</UtilityText>
                <InputSelect
                  options={seriesOptions}
                  onChange={item => setIdSerie(item.value)}
                  value={seriesOptions.find(serie => serie.value === idSerie.toString())}
                />
              </InputSelectContainer>
              <Spacing $size={16} />
              <Input
                id="label"
                placeholder="Etiqueta"
                value={label}
                textOnChange={(e: onChangeType) => setLabel(e.target.value)}
                fullWidth
              />
              <Spacing $size={16} />
              <Input
                id="price"
                placeholder="Precio *"
                value={price}
                textOnChange={(e: onChangeType) => setPrice(e.target.value)}
                fullWidth
              />
              <Spacing $size={16} />
              <Input
                id="discount"
                placeholder="Descuento"
                type="number"
                value={discount}
                textOnChange={(e: onChangeType) => setDiscount(parseInt(e.target.value))}
                fullWidth
              />
            </>
          )}
        </FormContainer>
        <Button onClick={handleSubmit} style={{ width: 'auto' }}>
          <Row>
            {urlId ? 'Editar' : 'Crear'}
            {buttonPressed && (
              <>
                <Spacing $vertical $size={16} />
                <Loader type="spinner" width={16} height={16} />
              </>
            )}
          </Row>
        </Button>
      </Container>
    </>
  );
};
