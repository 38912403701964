import styled from 'styled-components';

const Column = styled.div<{
  $width?: string;
  $textAlign?: string;
  $alignContent?: string;
  $justifyContent?: string;
  $alignItems?: string;
  $justifyItems?: string;
}>`
  display: grid;
  ${({ $width }) => $width && `width: ${$width}`};
  text-align: ${({ $textAlign }) => $textAlign || 'left'};
  align-content: ${({ $alignContent }) => $alignContent || 'inherit'};
  justify-content: ${({ $justifyContent }) => $justifyContent || 'inherit'};
  align-items: ${({ $alignItems }) => $alignItems || 'inherit'};
  justify-items: ${({ $justifyItems }) => $justifyItems || 'inherit'};
`;

export default Column;
