import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.gulfStream};
  left: 0;
  right: 0;
  height: 100%;
  text-align: -webkit-center;
  padding-top: 100px;
`;

export const FormContainer = styled.div`
  max-width: 400px;
  background: ${({ theme }) => theme.colors.softGray};
  margin: 0 20px;
  padding: 20px;
  border-radius: 20px;
`;
