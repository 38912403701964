import Column from 'components/Column';
import styled from 'styled-components';

export const Container = styled.footer`
  background: ${({ theme }) => theme.colors.gulfStream};
  padding: 16px;
`;

export const Content = styled(Column)`
  width: 33%;

  @media (max-width: 500px) {
    width: 100%;
    display: block;
    padding-bottom: 24px;
  }
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.mercury};
  ${({ theme }) => theme.fonts.semiBold};
  font-size: 24px;
`;

export const Description = styled(Title)<{ $bold?: boolean; $pressable?: boolean }>`
  ${({ theme, $bold }) => ($bold ? theme.fonts.semiBold : theme.fonts.regular)};
  font-size: 16px;
  ${({ $pressable }) => $pressable && 'cursor: pointer'};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
  }
`;
