import fonts from './fonts';
import colors from './colors';
import shadows from './shadows';

const theme = {
  fonts,
  colors,
  shadows,
};

export default theme;
