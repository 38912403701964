import styled from 'styled-components';

const BaseText = styled.span<{ $color?: string; $fontType?: string }>`
  color: ${({ $color, theme }) => $color || theme.colors.matterhorn};
  ${({ theme, $fontType }) => $fontType && theme.fonts[$fontType]};
`;

export const H1 = styled(BaseText)`
  font-size: 60px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.light};
  letter-spacing: -0.01em;
  line-height: 65px;
`;

export const H2 = styled(BaseText)`
  font-size: 40px;
  letter-spacing: -0.01em;
  line-height: 44px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.light};
`;

export const H3 = styled(BaseText)`
  font-size: 30px;
  letter-spacing: -0.01em;
  line-height: 35px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.light};
`;

export const BodyBig = styled(BaseText)`
  font-size: 25px;
  line-height: 30px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.light};
`;

export const H4 = styled(BaseText)`
  font-size: 20px;
  line-height: 25px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.light};
`;

export const Body = styled(BaseText)`
  font-size: 15px;
  line-height: 20px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.regular};
`;

export const BodySmall = styled(BaseText)`
  font-size: 13px;
  line-height: 18px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.regular};
`;

export const UtilityText = styled(BaseText)`
  font-size: 11px;
  line-height: 16px;
  ${({ theme, $fontType }) => !$fontType && theme.fonts.regular};
`;
