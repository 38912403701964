import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Row = styled(motion.div)<{
  $justifyContent?: string;
  $alignItems?: string;
  $pressable?: boolean;
  $flexWrap?: string;
  $gap?: number;
}>`
  justify-content: ${({ $justifyContent }) => $justifyContent || 'inherit'};
  align-items: ${({ $alignItems }) => $alignItems || 'inherit'};
  ${({ $pressable }) => $pressable && 'cursor: pointer;'};
  display: flex;
  flex-wrap: ${({ $flexWrap }) => $flexWrap || 'inherit'};
  gap: ${({ $gap }) => `${$gap || 0}px`};
`;

export const RowToGrid = styled(Row)<{ $switchToGrid?: number; $justifyItems?: string }>`
  justify-items: ${({ $justifyItems }) => $justifyItems || 'inherit'};
  ${({ $switchToGrid }) => `
    @media (max-width: ${$switchToGrid || 1000}px) {
      display: grid;
    }
  `}
`;
