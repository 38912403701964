import styled from 'styled-components';

export const Wrapper = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.matterhorn};
  border-radius: 10px;
  padding: 12px 16px;
  ${({ $fullWidth }) => $fullWidth && 'width: -webkit-fill-available'};
`;

const commonInputStyles = `
  border: none;
  min-width: 100%;
  letter-spacing: 0.15px;
  outline: 0px;
`;

export const StyledTextarea = styled.textarea`
  ${commonInputStyles}
  min-height: 100px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.matterhorn};
  ${({ theme }) => theme.fonts.regular};

  ::placeholder {
    color: ${({ theme }) => theme.colors.matterhorn};
  }
`;

export const StyledText = styled.input`
  ${commonInputStyles}
  background: ${({ theme }) => theme.colors.transparent};
  color: ${({ theme }) => theme.colors.matterhorn};
  ${({ theme }) => theme.fonts.regular};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.matterhorn};
  }
`;

export const Leyend = styled.span<{ withErrors?: boolean }>`
  padding-top: 6px;
  color: ${({ withErrors, theme }) => (withErrors ? theme.colors.errorRed : theme.colors.silver)};
  ${({ theme }) => theme.fonts.regular};
  font-size: 12px;
`;
