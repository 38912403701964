import supabase from 'supabase';
import { Dispatch } from 'redux';
import FAQS_TYPES from './types';
import { getUserId } from 'services/storage';

export const getFaqs =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(FAQS_TYPES.faqsStart());

    const { data, error } = await supabase.from('faqs').select();

    if (data?.length) dispatch(FAQS_TYPES.faqsSuccess(data));
    else if (error) dispatch(FAQS_TYPES.faqsError(error.message));
  };

export const deleteFaqs =
  (id: number) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(FAQS_TYPES.faqsStart());

    const { data, error } = await supabase
      .from('faqs')
      .update({ active: false, deleted_from: getUserId(), deleted_at: new Date() })
      .eq('id', id)
      .select();

    if (data?.length) dispatch(FAQS_TYPES.faqsSuccess(data));
    else if (error) dispatch(FAQS_TYPES.faqsError(error.message));
  };

export const updateFaqs =
  (id: number, question: string, answer: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(FAQS_TYPES.faqsStart());

    const { data, error } = await supabase
      .from('faqs')
      .update({ question, answer, updated_from: getUserId(), updated_at: new Date() })
      .eq('id', id)
      .select();

    if (data?.length) dispatch(FAQS_TYPES.faqsSuccess(data));
    else if (error) dispatch(FAQS_TYPES.faqsError(error.message));
  };

export const insertFaq =
  (question: string, answer: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(FAQS_TYPES.faqsStart());

    const { data, error } = await supabase
      .from('faqs')
      .insert({
        question,
        answer,
        active: true,
        inserted_from: getUserId(),
        inserted_at: new Date(),
      })
      .select();

    if (data?.length) dispatch(FAQS_TYPES.faqsSuccess(data));
    else if (error) dispatch(FAQS_TYPES.faqsError(error.message));
  };
