import supabase from 'supabase';
import { Dispatch } from 'redux';
import USER_TYPES from './types';
import { setExpiresIn } from 'services/storage';

export const getUserProfile =
  (user_id: string | null) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(USER_TYPES.userStart());

    const { data: loginData, error: loginError } = await supabase
      .from('users')
      .select('id, name, lastname, email, user_id, role')
      .eq('user_id', user_id);

    if (loginData?.length) {
      const date = new Date();
      date.setDate(date.getDate() + 1);

      dispatch(USER_TYPES.userSuccess(loginData[0]));
      setExpiresIn(date.getTime().toString());
    } else if (!loginData?.length && !loginError)
      dispatch(USER_TYPES.userError('Credenciales incorrectas.'));
    else if (loginError) dispatch(USER_TYPES.userError(loginError));
  };

export const logoutUser =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(USER_TYPES.userStart());

    const { error } = await supabase.auth.signOut();

    dispatch(USER_TYPES.userCleanUp());
    if (error) dispatch(USER_TYPES.userError(error));
  };
