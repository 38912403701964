import { motion } from 'framer-motion';
import { Row } from 'components/Row';
import styled from 'styled-components';

export const Container = styled(Row)`
  background: ${({ theme }) => theme.colors.white};
  padding: 16px;
  padding-right: 32px;
  border-bottom: 3px solid ${({ theme }) => theme.colors.gulfStream};
`;

export const BarContainer = styled.div<{ $isMenuOpen?: boolean; $isCartOpen?: boolean }>`
  background: ${({ theme }) => theme.shadows.white(0.2)};
  width: 100%;
  z-index: 90;
  ${({ $isMenuOpen, $isCartOpen }) =>
    ($isMenuOpen || $isCartOpen) &&
    `
      @media (max-width: 800px) {
        position: fixed;
        height: 100%;
      }
    `}
`;

export const Logo = styled.img`
  height: 80px;
  cursor: pointer;
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`;

export const MenuItem = styled(motion.div)`
  margin: 24px 16px;
  font-size: 22px;
  cursor: pointer;
  position: relative;
  color: ${({ theme }) => theme.colors.gulfStream};
  ${({ theme }) => theme.fonts.semiBold};
  letter-spacing: -0.5px;
`;

export const Underline = styled(motion.div)`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 3px;
  border-radius: 15px;
  background: ${({ theme }) => theme.colors.gulfStream};
  opacity: 0.85;
`;

export const MotionButton = styled(motion.button)`
  background: ${({ theme }) => theme.colors.transparent};
  border: none;
  z-index: 3;
  cursor: pointer;
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`;

export const MotionDiv = styled(motion.div)`
  position: fixed;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.gulfStream};
  z-index: 10;
`;

export const MenuItems = styled(Row)`
  @media (max-width: 800px) {
    display: none;
  }
`;

export const MotionContainer = styled(motion.div)<{ $selected?: boolean; $lastone?: boolean }>`
  padding: 8px 0 8px 8px;
  cursor: pointer;
  ${({ $selected, theme }) => $selected && `background: ${theme.colors.gulfStream}`};
  ${({ $selected, $lastone }) =>
    $selected &&
    $lastone &&
    `
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    `};
`;

export const HeaderItem = styled.span<{ $selected?: boolean }>`
  font-size: 12px;
  ${({ theme }) => theme.fonts.regular};
  color: ${({ theme, $selected }) => ($selected ? theme.colors.white : theme.colors.matterhorn)};
  padding-left: 8px;
`;
