import { Row } from 'components/Row';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const CarouselContainer = styled.div`
  padding: 0 24px;
`;

export const Container = styled(Row)`
  flex-direction: column;
  align-items: center;
  margin: 25px 0;
`;

export const Slider = styled.div`
  position: relative;
  height: 500px;
  width: 350px;
  overflow: hidden;

  @media (max-width: 500px) {
    height: 350px;
    width: 300px;
    padding: 24px 0;
  }
`;

export const MotionDiv = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  will-change: transform, opacity;

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
`;

export const ThumbnailContainer = styled.div`
  position: relative;
  height: 120px;
  width: 90px;

  @media (max-width: 500px) {
    height: 70px;
    width: 50px;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(:last-of-type) {
    margin-right: 5px;
  }
`;

export const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

export const ActiveThumbnail = styled.div<{ $active?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  transform: scaleX(0);
  transform-origin: left;
  background: ${({ theme }) => theme.colors.gray};
  transition: 1s cubic-bezier(0.56, 0.03, 0.12, 1.04) transform;

  ${({ $active }) => $active && 'transform: scaleX(1)'};
`;
