import { FC } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { HeaderListItem } from './HeaderListItem';
import { MotionDiv } from './styled';

interface Props {
  isOpen: boolean;
}

export const SlidingMenu: FC<Props> = ({ isOpen }) => {
  const navigate = useNavigate();
  const isMainScreen = window.location.pathname === '/';

  const variants = {
    open: () => ({
      clipPath: 'inset(0 0 0% 0%)',
      transition: {
        delay: 0.1,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    }),
    closed: {
      clipPath: 'inset(0 100% 100% 0%)',
      transition: {
        delay: 0.1,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
  };

  return (
    <motion.nav initial={false} animate={isOpen ? 'open' : 'closed'}>
      <MotionDiv variants={variants}>
        <HeaderListItem onclick={() => navigate('/')} label="Inicio" selected={isMainScreen} />
        <HeaderListItem
          onclick={() => navigate('/bio')}
          label="Quien Soy"
          selected={window.location.href.includes('bio')}
        />
        <HeaderListItem
          onclick={() => navigate('/art')}
          label="Arte"
          selected={window.location.href.includes('art')}
        />
        <HeaderListItem
          onclick={() => navigate('/faqs')}
          label="Preguntas Frecuentes"
          selected={window.location.href.includes('faqs')}
        />
        <HeaderListItem
          onclick={() => navigate('/contact')}
          label="Contacto"
          selected={window.location.href.includes('contact')}
          lastone
        />
      </MotionDiv>
    </motion.nav>
  );
};
