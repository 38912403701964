import { useNavigate } from 'react-router-dom';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { Footer } from 'components/Footer';
import { Button } from 'components/Button';
import { Container, Description, Title } from './styled';

export const NotFoundScreen = () => {
  const navigate = useNavigate();
  document.body.style.backgroundColor = theme.colors.softGray;

  const handleSubmit = () => {
    document.body.style.backgroundColor = theme.colors.white;
    navigate('/');
  };

  return (
    <>
      <Container>
        <Title>Oops! Parece que esta página no existe.</Title>
        <Spacing $size={16} />
        <Description>
          No encontramos la página que estas buscando..
          <br />
          Volvamos al lugar correcto
        </Description>
        <Spacing $size={24} />
        <Button onClick={handleSubmit}>Ir al Inicio</Button>
      </Container>
      <Footer />
    </>
  );
};
