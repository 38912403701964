import { FC, InputHTMLAttributes } from 'react';
import Column from 'components/Column';
import { StyledText, StyledTextarea, Wrapper, Leyend } from './styled';
import { UtilityText } from 'components/Typography';
import theme from 'config/theme';
import Spacing from 'components/Spacing';

export type onChangeType = React.ChangeEvent<HTMLInputElement>;
export type onKeyboardType = React.KeyboardEvent<HTMLInputElement>;
export type onChangeTextareaType = React.ChangeEvent<HTMLTextAreaElement>;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  placeholder?: string;
  fullWidth?: boolean;
  value: string | number;
  textOnChange?: (e: onChangeType) => void;
  textareaOnChange?: (e: onChangeTextareaType) => void;
  textarea?: boolean;
  leyend?: string;
  withErrors?: boolean;
  type?: string;
}

const Input: FC<InputProps> = ({
  placeholder = '',
  fullWidth,
  value,
  textOnChange,
  textareaOnChange,
  id,
  textarea = false,
  leyend,
  withErrors,
  type = 'text',
}) => {
  return (
    <Column $width="100%">
      {(!!value || typeof value === 'number') && (
        <>
          <UtilityText $color={withErrors ? theme.colors.errorRed : theme.colors.matterhorn}>
            {placeholder}
          </UtilityText>
          <Spacing $size={4} />
        </>
      )}
      <Wrapper $fullWidth={fullWidth}>
        {!textarea ? (
          <StyledText
            value={value}
            placeholder={placeholder}
            onChange={textOnChange}
            id={id}
            type={type}
          />
        ) : (
          <StyledTextarea
            value={value}
            placeholder={placeholder}
            onChange={textareaOnChange}
            id={id}
          />
        )}
      </Wrapper>
      {leyend && <Leyend withErrors={withErrors}>{leyend}</Leyend>}
    </Column>
  );
};

export default Input;
