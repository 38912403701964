const fonts = {
  regular: () => {
    return {
      fontFamily: 'DMSans-Regular',
    };
  },
  light: () => {
    return {
      fontFamily: 'DMSans-Light',
    };
  },
  semiBold: () => {
    return {
      fontFamily: 'DMSans-SemiBold',
    };
  },
};

export default fonts;
