import { Row } from 'components/Row';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  cursor: pointer;
  min-width: 375px;
  max-width: 375px;

  @media (max-width: 500px) {
    min-width: fit-content;
  }
  @media (max-width: 800px) {
    max-width: 100%;
  }
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.shaft};
  ${({ theme }) => theme.fonts.semiBold};
  text-transform: uppercase;
`;

export const InfoTitle = styled.span`
  font-size: 24px;
  color: ${({ theme }) => theme.colors.shaft};
  ${({ theme }) => theme.fonts.semiBold};
`;

export const InfoDescription = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gulfStream};
  ${({ theme }) => theme.fonts.regular};
`;

export const Thumb = styled.div`
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  transition: all 0.5s ease-out;
  box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.shadows.black(0.08)};

  // &:hover {
  //   box-shadow: 0px 0px 100px 0px ${({ theme }) => theme.shadows.black(0.08)};
  // }
`;

export const StyledImage = styled.img`
  transition: all 0.5s ease-out;
  width: 100%;
  max-height: 300px;
  object-fit: cover;
`;

export const TopLabel = styled(Row)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 20px;
`;

export const InfoContainer = styled(Row)`
  padding: 30px 20px;
`;
